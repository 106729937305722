import { useUserContext } from '~/stores/useUserContext';
import { CustomerGroupId } from '~/@types/customerGroupIds';
import { storeToRefs } from 'pinia';

export function useDashboardDynamicLink() {
  return computed(() => {
    const { customerTypeId } = storeToRefs(useUserContext());
    const currCustomerTypeId = customerTypeId.value;

    let linkText = 'hotlink.myPraxis.myDashboard';

    switch (currCustomerTypeId) {
      case CustomerGroupId.DENTIST:
      case CustomerGroupId.SURGERY:
      case CustomerGroupId.DENTIST_WITHOUT_VERIFICATION:
      case CustomerGroupId.SURGERY_WITHOUT_VERIFICATION:
        linkText = 'header.links.praxis';
        break;
      case CustomerGroupId.OTHER:
      case CustomerGroupId.STUDENT:
        linkText = 'hotlink.myPraxis.myDashboard';
        break;
      case CustomerGroupId.COMPANY:
        linkText = 'hotlink.myPraxis.myCompany';
        break;
      case CustomerGroupId.PHARMACY:
      case CustomerGroupId.PHARMACY_WITHOUT_VERIFICATION:
        linkText = 'hotlink.myPraxis.myPharmacy';
        break;
      case CustomerGroupId.DENTAL_LAB:
      case CustomerGroupId.PRACTICE_LAB:
        linkText = 'hotlink.myPraxis.myLabor';
        break;

      default:
        linkText = 'hotlink.myPraxis.myDashboard';
        break;
    }
    return {
      linkText,
      currCustomerTypeId,
    };
  });
}

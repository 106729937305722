<template>
  <div ref="container">
    <div class="flex justify-between p-md pb-sm">
      <span class="font-bold font-deco-alt text-alt-2xl">{{
        t('header.search.lastSearchqueries')
      }}</span>

      <div
        v-if="searchHistory?.length"
        class="flex items-center text-primary-base hover:cursor-pointer"
        @click="clearSearchHistory()"
      >
        <FaIcon icon-class="far fa-trash" classes="h-[16px] w-auto mr-xs" />
        <span>{{
          t(
            isMobile
              ? 'header.search.clearSearchMobile'
              : 'header.search.clearSearch',
          )
        }}</span>
      </div>
    </div>
    <div>
      <NuxtLink
        v-for="(search, index) in searchQueries"
        :key="index"
        :to="`/shop/search/${encodeURIComponent(search)}`"
      >
        <div
          class="font-text px-md py-xs hover:bg-primary-lightest hover:cursor-pointer"
          @click="$emit('updateInput', search)"
          @key.enter="$emit('updateInput', search)"
        >
          <FaIcon
            icon-class="far fa-clock-rotate-left"
            classes="mr-xs text-grey-dark"
          />
          <span>{{ search }}</span>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
import { useSearchbar } from './useSearchbar';
import { SSR_safe_mq_breakpointIsMobile } from '~/injectionSymbols';

const isMobile = inject(SSR_safe_mq_breakpointIsMobile);
const { t } = useTrans();
const container = ref();
const searchQueries = ref();

onClickOutside(container, () => emits('closeHistory'));

const emits = defineEmits<{
  (e: 'closeHistory'): void;
  (e: 'updateInput', value: string): void;
}>();

const { clearSearchHistory, searchHistory } = useSearchbar();

onMounted(() => {
  watch(
    searchHistory,
    async () => {
      searchQueries.value = searchHistory.value.slice(-5).reverse();
    },
    { immediate: true },
  );
  if (isMobile?.value) usePreventBodyScroll(true);
});

onUnmounted(() => {
  usePreventBodyScroll(false);
});
</script>

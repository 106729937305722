import { storeToRefs } from 'pinia';
import { mq_isTouch } from '~~/src/injectionSymbols';
import { type LinkedNavItem, useNav } from '~~/src/stores/useNav';
import { showItem, maxDepthCorrection, maxNavigationDepth } from '../utils';
import { EStyleType as EStyleTypeStyledMy } from '@/components/layout/header/components/styledMy/useStyledMy';

export enum EMainNavLinkStyle {
  ACTIVE_PARENT = 'item item--activeParent',
  PARENT = 'item item--parent',
  NORMAL = 'item item--normal',
  ACTIVE = 'item item--active',
  HIDDEN = 'item item--hidden',
}

export interface NavLink {
  navItem: LinkedNavItem;
  cssClass: EMainNavLinkStyle;
  noLink?: boolean;
}

export default function useMainNavState() {
  const router = useRouter();
  const mainNavEl = ref<HTMLElement>(null);
  const isTouch = inject(mq_isTouch);

  const navStore = useNav();
  const { mainNav_activeStructure: activeNavStructure } = storeToRefs(navStore);

  const state = reactive({
    hoverNav: {
      hoveredItem: null as LinkedNavItem,
      isHovered: false,
      show: false,
      navItems: [] as LinkedNavItem[],
      isLevel_0: false,
      x_pos: 0,
      width: 0,
    },
    toggleNav: {
      show: false,
    },
  });

  function touchClick(event: any, link: NavLink) {
    if (link.noLink) {
      const isHoveredItem = link.navItem === state.hoverNav.hoveredItem;
      const isActiveItem = link.navItem === activeNavStructure.value.activeItem;

      if (isHoveredItem) {
        if (isActiveItem) {
          navItemUnhover();
        } else {
          router.push(link.navItem.navUri);
          navItemUnhover();
        }
      } else {
        navItemHover(
          event,
          link.navItem,
          activeNavStructure.value.activeItem.level === 0,
          link.cssClass === EMainNavLinkStyle.ACTIVE_PARENT ||
            link.cssClass === EMainNavLinkStyle.PARENT,
          true,
        );
      }
    }
  }

  /**
   * Sort and structure the main navigation for easy use in the HTML
   * Add CSS Classes/Descriptors to items
   * Look at the designs to understand this, as this is basically the logic-setup for the designs! :)
   */
  const mainNavLinks = computed(() => {
    const links = [] as NavLink[];

    if (
      !activeNavStructure.value.activeItem &&
      !activeNavStructure.value.parents
    ) {
      return links;
    }

    //const activeItem = maxDepthCorrection(activeNavStructure.value.activeItem);
    const activeItem = maxDepthCorrection(getActiveItem());

    /**
     * @workaround for only showing the toplevel navItems
     */
    function getActiveItem() {
      let parent = activeNavStructure.value.activeItem.parent;
      while (parent?.parent) {
        parent = parent.parent;
      }
      return parent ?? activeNavStructure.value.activeItem;
    }

    const parentsWithoutLevel_0 = [] as LinkedNavItem[];
    let parent = activeItem.parent;
    while (parent && parent.level > 0) {
      parentsWithoutLevel_0.push(parent);
      parent = parent.parent;
    }
    parentsWithoutLevel_0.reverse();

    for (let i = 0; i < parentsWithoutLevel_0.length; i++) {
      const parent = parentsWithoutLevel_0[i];
      links.push({
        navItem: parent,
        cssClass: EMainNavLinkStyle.PARENT,
      });
    }

    if (
      activeItem.children.length !== 0 &&
      activeItem.level !== 0 &&
      activeItem.level < maxNavigationDepth - 1
    ) {
      links.push({
        navItem: activeItem,
        cssClass: EMainNavLinkStyle.ACTIVE_PARENT,
      });
    }

    if (
      activeItem.children.length === 0 ||
      activeItem.level >= maxNavigationDepth - 1
    ) {
      for (const neighbour of activeItem.neighbours) {
        links.push({
          navItem: neighbour,
          cssClass:
            neighbour === activeItem
              ? EMainNavLinkStyle.ACTIVE
              : EMainNavLinkStyle.NORMAL,
        });
      }
    }

    for (const child of activeItem.children) {
      //Display shop-children in minilu toplevel nav instead of shop
      if (activeItem.level === 0 && useSiteIdent().includes('minilu')) {
        if (
          child.level < maxNavigationDepth &&
          child.title.toLowerCase() !== 'navigation.shop.label'
        ) {
          links.push({
            navItem: child,
            cssClass: EMainNavLinkStyle.NORMAL,
          });
        } else if (
          child.level < maxNavigationDepth &&
          child.title.toLowerCase() === 'navigation.shop.label'
        ) {
          for (const shopChild of child.children) {
            links.push({
              navItem: shopChild,
              cssClass: EMainNavLinkStyle.NORMAL,
            });
          }
        }
      } else {
        if (child.level < maxNavigationDepth) {
          links.push({
            navItem: child,
            cssClass: EMainNavLinkStyle.NORMAL,
          });
        }
      }
    }

    // Filter our hidden items
    links.forEach((link) => {
      if (!showItem(link.navItem, activeItem.id)) {
        link.cssClass = EMainNavLinkStyle.HIDDEN;
      }
    });

    return links.map((link) => ({
      ...link,
      noLink:
        isTouch.value &&
        (link.cssClass === EMainNavLinkStyle.PARENT ||
          link.cssClass === EMainNavLinkStyle.ACTIVE_PARENT ||
          activeNavStructure.value.activeItem.level === 0),
    }));
  });

  let timeoutId: NodeJS.Timeout = null;

  function mouseEnter(event: MouseEvent, link: NavLink) {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      navItemHover(
        event,
        link.navItem,
        activeNavStructure.value.activeItem.level === 0,
        link.cssClass === EMainNavLinkStyle.ACTIVE_PARENT ||
          link.cssClass === EMainNavLinkStyle.PARENT,
      );
    }, 100);
  }

  function mouseLeave() {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  }

  /**
   *
   * @param navItem The NavItem that will determine the content of the hover nav
   * @param isLevel_0 If the ActiveNavItem is Level 0
   * Values set here will pe passed as props to the Hover-Nav Component
   */
  function navItemHover(
    event: any,
    navItem: LinkedNavItem,
    isLevel_0 = false,
    isParent = false,
    noLink = false,
  ) {
    const eventTarget = noLink
      ? (event.target as HTMLElement).closest('.js-nav-item')
      : (event.target as HTMLElement);
    state.hoverNav.x_pos = eventTarget.getBoundingClientRect().x;
    state.hoverNav.width = eventTarget.getBoundingClientRect().width;

    if (state.hoverNav.hoveredItem === navItem) {
      return;
    }
    state.hoverNav.hoveredItem = navItem;
    if (
      activeNavStructure.value.parents.includes(navItem) ||
      (activeNavStructure.value.activeItem === navItem &&
        activeNavStructure.value.activeItem.children.length > 0) ||
      isLevel_0 ||
      navItem.children.length > 0
    ) {
      if (isLevel_0) {
        state.hoverNav.navItems = navItem.children.filter((item) =>
          showItem(item, activeNavStructure.value.activeItem.id),
        );
        state.hoverNav.isLevel_0 = true;
      } else if (isParent) {
        state.hoverNav.navItems = navItem.neighbours.filter((item) =>
          showItem(item, activeNavStructure.value.activeItem.id),
        );
        state.hoverNav.isLevel_0 = false;
      } else {
        state.hoverNav.navItems = navItem.children.filter((item) =>
          showItem(item, activeNavStructure.value.activeItem.id),
        );
        state.hoverNav.isLevel_0 = false;
      }
      if (state.hoverNav.navItems.length !== 0) {
        state.hoverNav.show = true;
      } else {
        state.hoverNav.navItems = [];
        state.hoverNav.show = false;
      }
    } else {
      state.hoverNav.navItems = [];
      state.hoverNav.show = false;
    }
  }

  function navItemUnhover() {
    state.hoverNav.show = false;
    state.hoverNav.isHovered = false;
    state.hoverNav.hoveredItem = null;
  }

  function linkShowsDropdownIcon(cssClass: EMainNavLinkStyle) {
    return (
      (cssClass === EMainNavLinkStyle.PARENT ||
        cssClass === EMainNavLinkStyle.ACTIVE_PARENT) &&
      activeNavStructure.value.activeItem.level !== 0
    );
  }

  //--- Toggle Menu
  function toggleMenuClick() {
    state.toggleNav.show = !state.toggleNav.show;
    state.hoverNav.show = false;
    state.hoverNav.isHovered = false;
    state.hoverNav.hoveredItem = null;
  }

  //--- Hover-Menu
  function hoverMenuHover() {
    state.hoverNav.isHovered = true;
  }

  function hoverMenuUnhover() {
    state.hoverNav.show = false;
    state.hoverNav.isHovered = false;
    state.hoverNav.hoveredItem = null;
  }

  function getMyNavStyle(linkCss: EMainNavLinkStyle, isHovered: boolean) {
    switch (true) {
      case !linkCss.includes('active') && !isHovered:
        return EStyleTypeStyledMy.lightAlt;
      case linkCss.includes('active') && !isHovered:
        return EStyleTypeStyledMy.light;
      default:
        return EStyleTypeStyledMy.default;
    }
  }

  onMounted(() => {
    watch(mainNavEl, async () => {
      state.hoverNav.show = false;
      state.hoverNav.isHovered = false;
      state.hoverNav.hoveredItem = null;
    });
  });

  /**
   * @workaround for showing only the toplevel navItems
   */
  const activeNavStructureComputed = computed(() => {
    const { mainNav_activeStructure: activeNavStructureFromStore } =
      storeToRefs(navStore);
    let parent = activeNavStructureFromStore.value.activeItem.parent;
    let activeNavItem = activeNavStructureFromStore.value.activeItem.parent;

    while (parent?.parent) {
      parent = parent.parent;
      if (
        (parent.type !== 'root' && useSiteIdent().startsWith('default')) ||
        (parent.type !== 'root' &&
          useSiteIdent().startsWith('minilu') &&
          parent.title !== 'navigation.shop.label')
      ) {
        activeNavItem = parent;
      }
    }
    return {
      ...activeNavStructureFromStore.value,
      activeItem: parent ?? activeNavStructureFromStore.value.activeItem,
      activeNavItem,
    };
  });

  return {
    state,
    touchClick,
    mainNavLinks,
    mouseEnter,
    mouseLeave,
    navItemHover,
    navItemUnhover,
    linkShowsDropdownIcon,
    toggleMenuClick,
    hoverMenuHover,
    hoverMenuUnhover,
    getMyNavStyle,
    activeNavStructureComputed,
  };
}

<template>
  <ContainerBasic
    :container-type="EContainerType.SHOP_CONTENT"
    :class="{
      [navStore.layoutBGColor]: navStore.layoutBGColor,
    }"
  >
    <BannerPosition :position="BannerPositions.FOOTER" classes="mb-xl" />
  </ContainerBasic>
</template>
<script setup lang="ts">
import BannerPosition from '~/components/banner/bannerPosition.vue';
import { BannerPositions } from '~/@types/bannerPositions';
import ContainerBasic from '@/components/components/container-basic.vue';
import { EContainerType } from '~/@types/container-type';
import { useNav } from '~/stores/useNav';

const navStore = useNav();
</script>

export function useCloseOnLeave(stayOpenedClass: string, close: () => void) {
  return {
    init() {
      const handler = (event: MouseEvent) => {
        const el = event.target as HTMLElement;
        if (!el.closest(`.${stayOpenedClass}`)) {
          handleClose();
        }
      };
      const leaveHandler = () => {
        handleClose();
      };

      window.addEventListener('mousemove', handler, { passive: true });
      document.addEventListener('mouseleave', leaveHandler, { passive: true });

      function handleClose() {
        close();
        document.removeEventListener('mouseleave', leaveHandler);
        window.removeEventListener('mousemove', handler);
      }
    },
  };
}

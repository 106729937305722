<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="w-full bg-status-warn-lighter shrink-0">
    <div class="flex items-center mx-auto max-w-screen-2xl px-sm">
      <div
        class="flex bg-status-warn-light pt-[7px] pb-[8px] px-[8px] md:px-lg place-self-stretch items-center"
      >
        <FaIcon
          icon-class="fal fa-circle-exclamation"
          classes="mr-0 md:mr-2 w-[20px] h-[20px] whitespace-nowrap"
        />
        <p
          v-if="!isMobile"
          class="font-deco text-alt-xl leading-alt-xs"
          v-html="t('infoBanner.hint')"
        ></p>
      </div>
      <div
        class="my-xs mx-sm mr-[22px] md:mx-md text-[12px] md:text-sm leading-2xs md:leading-xs"
      >
        {{ t('infoBanner.text') }}
      </div>
      <div class="absolute border-red-500 right-sm md:right-md top-xs">
        <button @click="emit('closeInfobanner', false)">
          <FaIcon
            icon-class="far fa-xmark"
            classes="text-text-base !h-[16px] md:!h-[20px]"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mq_breakpointIsMobile } from '~~/src/injectionSymbols';
import FaIcon from '@/components/fa-icon.vue';

const isMobile = inject(mq_breakpointIsMobile);
const { t } = useTrans();

const emit = defineEmits<{
  (e: 'closeInfobanner', value: boolean): void;
}>();
</script>

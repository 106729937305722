<template>
  <LoginStatus @click="$emit('close')">
    <div
      v-if="userContext.initialized"
      class="flex flex-row px-md gap-sm my-sm py-[9px]"
    >
      <UserIcon :size="24" />
      <div class="w-full pr-md">
        <p class="overflow-hidden whitespace-nowrap overflow-ellipsis">
          {{ t('user.loginStatus.salutation') }}
          {{ userContext.userData?.name1 }}
        </p>
      </div>
    </div>
  </LoginStatus>

  <div class="bg-border-light h-[1px] mx-md mb-sm"></div>
  <NuxtLink
    class="item noIcon"
    to="/account"
    :class="{ active: $route.path === '/account' }"
    @click="$emit('close')"
    >{{ t('header.links.overview') }}</NuxtLink
  >
  <NuxtLink
    class="item noIcon"
    to="/account/orders"
    :class="{ active: $route.path === '/account/orders' }"
    data-testid="headerLinkOrders"
    @click="$emit('close')"
    >{{ t('header.links.orders') }}</NuxtLink
  >
  <NuxtLink
    class="item noIcon"
    to="/account/returns"
    :class="{ active: $route.path === '/account/returns' }"
    data-testid="headerLinkReturns"
    @click="$emit('close')"
    >{{ t('header.links.sendback') }}</NuxtLink
  >
  <NuxtLink
    class="item noIcon"
    to="/account/profile"
    :class="{ active: $route.path === '/account/profile' }"
    data-testid="headerLinksProfile"
    @click="$emit('close')"
    >{{ t('header.links.account') }}</NuxtLink
  >

  <div
    v-if="session.isLoggedIn"
    class="bg-border-light h-[1px] mx-md my-sm"
  ></div>
  <div v-if="session.isLoggedIn">
    <div
      data-testid="MyPraxisLogoutButten"
      class="item gap-[13px]"
      @click="session.logout()"
    >
      <span class="text-border-dark">
        <FaIcon icon-class="far arrow-right-from-bracket" />
      </span>
      <span>
        <span>{{ t('user.loginStatus.logout') }}</span>
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { LoginStatus } from '@/complib';
import UserIcon from '~/components/user/userIcon/userIcon.vue';
import { useUserContext } from '~/stores/useUserContext';
import FaIcon from '@/components/fa-icon.vue';
import { useSessionStore } from '~/stores/useSessionStore';

defineEmits<{
  (e: 'close'): void;
}>();

const { t } = useTrans();
const userContext = useUserContext();
const session = useSessionStore();
</script>
<style scoped lang="postcss">
.item {
  @apply flex flex-row cursor-pointer flex-nowrap font-deco-alt text-alt-lg py-xs px-md hover:bg-primary-lightest hover:text-text-base;

  &.noIcon {
    @apply pl-[63px];
  }

  &.active {
    @apply bg-primary-base text-white;
  }
}
</style>

<template>
  <div class="max-w-full">
    <div
      v-if="showBackBtn"
      :style="showBackBtn ? 'opacity:1;' : 'opacity:0; width:0px;'"
      class="left-0 border-white border-r-1 scrollhandle"
      :class="arrowClass"
      @click="scrollViaHandle('l')"
    >
      <FaIcon icon-class="fas fa-chevron-left" classes="fa-lg" />
    </div>
    <div
      ref="wrapper"
      class="relative flex flex-row w-full max-w-full overflow-hidden overflow-x-scroll scroll-smooth flex-nowrap"
    >
      <slot></slot>
      <div class="px-[25px]"></div>
    </div>
    <div
      v-if="showFwdBtn"
      :style="showFwdBtn ? 'opacity:1;' : 'opacity:0; width:0px;'"
      class="right-0 border-white border-l-1 scrollhandle"
      :class="arrowClass"
      @click="scrollViaHandle('r')"
    >
      <FaIcon icon-class="fas fa-chevron-right" classes="fa-lg" />
    </div>
  </div>
</template>

<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
defineProps({
  arrowClass: {
    type: String,
    required: false,
    default: '',
  },
});

const wrapper = ref<HTMLElement>(null);
const showFwdBtn = ref(false);
const showBackBtn = ref(false);
const acceptableOverflow = 75; //px
const { x: scroll_x, isScrolling } = useScroll(wrapper);
const onResizeDebounce = useDebounceFn(() => {
  scrollViaHandle('start');
  setHandles(scroll_x.value);
}, 100);
const onScrollDebounce = useDebounceFn((scroll_x: number) => {
  setHandles(scroll_x);
}, 300);

onMounted(() => {
  setHandles(scroll_x.value);
  useResizeObserver(wrapper, () => {
    onResizeDebounce();
  });
});

watch(isScrolling, async (newVal) => {
  if (newVal === false) {
    onScrollDebounce(scroll_x.value);
  }
});

function scrollViaHandle(direction: 'l' | 'r' | 'start') {
  if (!wrapper.value) return;
  const wrapperWidth = wrapper.value?.getBoundingClientRect().width ?? 0;
  const wrapperScrollWidth = wrapper.value.scrollWidth;

  if (wrapperScrollWidth - wrapperWidth < acceptableOverflow) {
    showBackBtn.value = false;
    showFwdBtn.value = false;
    wrapper.value.scrollLeft = 0;
    return;
  }

  if (direction === 'r') {
    wrapper.value.scrollLeft = wrapper.value.scrollLeft + 300;
  } else if (direction === 'l') {
    wrapper.value.scrollLeft = wrapper.value.scrollLeft - 300;
  } else if (direction === 'start') {
    wrapper.value.scrollLeft = 0;
  }
}

function setHandles(scroll_x: number) {
  if (!wrapper.value) return;
  const wrapperWidth = wrapper.value?.getBoundingClientRect().width;
  const wrapperScrollWidth = wrapper.value.scrollWidth;
  if (scroll_x <= acceptableOverflow - 25) {
    wrapper.value.scrollLeft = 0;
    showBackBtn.value = false;
  } else {
    showBackBtn.value = true;
  }

  if (scroll_x + acceptableOverflow >= wrapperScrollWidth - wrapperWidth) {
    showFwdBtn.value = false;
    if (wrapper.value.scrollLeft !== 0) {
      wrapper.value.scrollLeft = 999999;
    }
  } else {
    showFwdBtn.value = true;
  }
}
</script>

<style scoped lang="postcss">
.scrollhandle {
  @apply absolute bottom-0 z-10 text-white w-xl h-[60px] cursor-pointer transition-all duration-75;
  :first-child {
    @apply p-0 mt-[20px] mx-[18px] mb-0;
  }
}
</style>

import type { LinkedNavItem } from '~~/src/stores/useNav';
import { MyAccountIds, MyProductIds } from '@/@types/navigationIds';

export function showItem(navItem: LinkedNavItem, activeItemdId: string) {
  return (
    (navItem.isHidden && activeItemdId === navItem.id) ||
    (navItem.id === MyAccountIds.MY_ACCOUNT &&
      navItem.childIds.includes(activeItemdId)) ||
    (navItem.id === MyProductIds.MY_PRODUCTS &&
      navItem.childIds.includes(activeItemdId)) ||
    !navItem.isHidden
  );
}

export const maxNavigationDepth = 4;
export function maxDepthCorrection(
  navItem: LinkedNavItem,
  maxDepth = maxNavigationDepth,
) {
  let currentNavItem = navItem;
  while (currentNavItem.level >= maxDepth && currentNavItem.parent) {
    currentNavItem = currentNavItem.parent;
  }
  return currentNavItem;
}

<template>
  <div>
    <div class="flex flex-row items-center">
      <div class="flex flex-col items-left">
        <div class="font-bold text-md leading-[1rem]">
          {{ t('shop.cart.costSummary.subTotal') }}
        </div>
        <VatShippingHint
          keypath="shop.cart.costSummary.excludingVATAndShipping"
          tag="div"
          classes="text-sm text-border-dark"
        />
      </div>
      <div class="ml-auto font-bold text-alt-3xl font-deco-alt leading-0">
        {{
          n(
            (cart.price?.subtotal ?? 0) + (cart.voucher?.price?.unitPrice ?? 0),
            'currency',
          )
        }}
      </div>
    </div>
    <div class="flex flex-col mt-md flex-nowrap gap-y-sm">
      <CheckoutButton
        testid="toCartButton"
        @click="$emit('linkClick')"
      ></CheckoutButton>
      <ToCartButton
        :label="t('shop.cart.general.basketLink')"
        @click="$emit('linkClick')"
      ></ToCartButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCartStore } from '~/stores/useCart';
import { storeToRefs } from 'pinia';
import CheckoutButton from '@/components/shop/checkout/checkoutButton.vue';
import ToCartButton from './toCartButton.vue';
import VatShippingHint from '@/components/shop/atoms/vatShippingHint.vue';

const { cart } = storeToRefs(useCartStore());
const { n, t } = useTrans();

defineEmits(['linkClick']);
</script>

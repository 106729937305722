export function useStyledMy(text: string) {
  // @TODO: Add Pluralization once its needed
  const { t } = useTrans();
  const my = t('header.links.my');
  const myMasculine = t('header.links.my.masculine');

  const isStyled = text.includes(my) || text.includes(myMasculine);
  const prev: string[] = [];
  const next: string[] = [];
  let styled = '';

  text.split(' ').forEach((word) => {
    if (word === my || word === myMasculine) styled = word;
    else if (styled) next.push(word);
    else prev.push(word);
  });
  return { isStyled, styled, prev, next };
}

export enum EStyleType {
  default,
  light,
  lightAlt,
  none,
}

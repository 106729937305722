<template>
  <div class="flex flex-col h-full">
    <div
      v-show="isNotification"
      class="relative flex w-full text-center sm:text-left h-xl bg-status-success-lighter px-md"
    >
      <span class="mx-auto my-auto sm:mx-0">{{
        t('shop.cart.general.articleAddSuccess')
      }}</span>
      <div
        class="hidden -translate-y-1/2 cursor-pointer sm:absolute sm:flex top-1/2 right-sm"
        @click="$emit('close')"
      >
        <FAIcon
          icon-class="fas fa-xmark"
          class="text-xl text-grey-base"
        ></FAIcon>
      </div>
    </div>
    <div v-if="cartItemAmount > 0" class="h-full overflow-auto px-md">
      <div class="flex flex-col">
        <div class="sticky top-0 z-10 bg-white pt-md pb-sm -mx-md px-md">
          <OrderSummary @link-click="$emit('close')" />
        </div>
        <div class="flex-col hidden sm:flex flex-nowrap gap-sm pb-md">
          <div
            class="w-full h-0 border-solid border-grey-base border-b-[1px] mt-sm"
          ></div>
          <div class="mt-sm font-bold font-deco text-[22px]">
            {{ cartItemAmount }}
            {{ t('shop.cart.general.article') }}
          </div>
          <HasItemWithCampaign :line-items="cart.items" />
          <LineItems
            :line-items="cart.items"
            :variant="LineItemVariant.miniBasket"
            :voucher="cart.voucher"
            @click="$emit('close')"
          />
        </div>
      </div>
    </div>
    <div v-else class="h-full px-md py-md">
      <EmptyBasket class="!w-full" :alt-layout="true" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCartStore } from '~/stores/useCart';
import { storeToRefs } from 'pinia';
import { UseStateKeys } from '@/useStateKeys';
import OrderSummary from './orderSummary.vue';
import FAIcon from '@/components/fa-icon.vue';
import EmptyBasket from '@/components/shop/shoppingcart/emptyBasket/emptyBasket.vue';
import { LineItemType } from '~/@types/lineItemType';
import LineItems from '~/components/page/components/lineItems/lineItems.vue';
import { LineItemVariant } from '~/components/page/components/lineItems/types';
import HasItemWithCampaign from './hasItemWithCampaign.vue';

const { t } = useTrans();
const { cart, loadCart } = useCartStore();
const { cartItemAmount } = storeToRefs(useCartStore());
const isNotification = useState(UseStateKeys.HOTLINK_BASKET_NOTIFICATION);

defineEmits(['close']);

onMounted(async () => {
  await loadCart();
});

LineItemType;
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="suggestionResult?.products?.length > 0" ref="container">
    <div class="font-bold font-deco-alt text-alt-2xl pl-md pt-md pb-sm">
      {{ t('header.search.products') }}
    </div>
    <NuxtLink
      v-for="item in suggestionResult?.products"
      :key="item.id"
      :to="getProductLink(item.product.name, item.product.productNumber)"
      target="_self"
      class="flex cursor-pointer py-sm active:bg-primary-lightest hover:bg-primary-lightest gap-sm px-md"
      @click="$emit('closeSuggestions')"
    >
      <div class="flex items-center justify-center bg-white h-xl aspect-square">
        <ProductImage
          :image-url="item.product?.media?.cover"
          :alt-text="item.product?.media?.alt"
          class="max-w-full max-h-full my-auto p-2xs"
        />
      </div>
      <div class="flex flex-col">
        <p
          class="font-bold font-deco text-alt-lg"
          v-html="getHighlightedString(item.product.name, true)"
        ></p>
        <div class="flex font-text">
          <p v-if="item.product.variantName">
            {{ item.product.variantName }} |
            {{ item.product.manufacturer.name }}
          </p>
          <p v-else>{{ item.product.manufacturer.name }}</p>
        </div>
      </div>
    </NuxtLink>

    <BasicLink
      :label="t('header.search.showAll')"
      :link="`/shop/search/${encodeURIComponent(searchInput)}`"
      target="_self"
      icon="fas fa-chevron-right"
      :icon-position="EPosition.RIGHT"
      class="text-primary-base pl-md font-text pt-xs"
      @click="emits('closeSuggestions')"
    />

    <div
      v-if="suggestionResult?.suggestions?.length > 0"
      class="font-bold font-deco-alt text-alt-2xl pl-md pt-md pb-sm"
    >
      {{ t('header.search.searchSuggestions') }}
    </div>
    <div
      v-for="(item, index) in suggestionResult?.suggestions"
      :key="index"
      @click="$emit('updateInput', item)"
    >
      <NuxtLink
        v-if="index <= 7"
        :to="`/shop/search/${encodeURIComponent(item)}`"
      >
        <div
          class="flex cursor-pointer py-xs hover:!bg-primary-lightest gap-sm px-md items-center font-text"
        >
          <FaIcon
            icon-class="far magnifying-glass"
            class="text-xl text-text-light"
          />
          <p v-html="getHighlightedString(item)" />
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import BasicLink from '@/components/components/core/basicLink/basic-link.vue';
import { EPosition } from '@/@types/basic-link';
import { getProductLink } from '~/helpers/shop';
import FaIcon from '@/components/fa-icon.vue';
import type { SearchSuggestionResult } from '~/server/api/[site]/search/suggestions.post';
import { SSR_safe_mq_breakpointIsMobile } from '~/injectionSymbols';
import { ProductImage } from '@/complib';

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  suggestionResult: {
    type: Object as PropType<SearchSuggestionResult>,
    requried: true,
  },
  searchInput: {
    type: String,
    required: true,
  },
});

const emits = defineEmits<{
  (e: 'closeSuggestions'): void;
  (e: 'updateInput', value: string): void;
}>();

const { t } = useTrans();
const container = ref();
const isMobile = inject(SSR_safe_mq_breakpointIsMobile);

onClickOutside(container, () => emits('closeSuggestions'));

const getHighlightedString = (
  productName: string,
  isProductSuggestion = false,
) => {
  try {
    let customProductName = productName;
    const regex = new RegExp(props.searchInput, 'gi');

    customProductName = customProductName.replace(regex, (match) => {
      return isProductSuggestion
        ? `<span class="text-primary-base">${match}</span>`
        : `<span class="font-bold">${match}</span>`;
    });

    return customProductName;
  } catch (error) {
    return productName;
  }
};

onMounted(() => {
  if (isMobile?.value) usePreventBodyScroll(true);
});

onUnmounted(() => {
  usePreventBodyScroll(false);
});
</script>

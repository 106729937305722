<template>
  <div
    ref="element"
    class="h-[72px] flex items-center justify-center md:mr-0"
    :class="[
      stayOpenedClass,
      { 'mr-2xs': notifications > 0, 'mr-sm': notifications > 99 },
    ]"
  >
    <button
      class="flex items-center justify-center rounded-full cursor-pointer activeTrigger touch:md:rounded-none lg:rounded-none"
      :data-testid="testid"
      :class="[
        stayOpenedClass,
        { active: isActive, 'bg-primary-base': isActivePage && !isActive },
      ]"
      @click="
        () => {
          if (!isTouch) {
            to ? $router.push(to) : $emit('click');
          }
        }
      "
      @mouseenter="
        () => {
          if (!isTouch) setIsActive(true);
        }
      "
      @touchend="onTouchClick"
    >
      <div
        :class="[
          'flex justify-center gap-2 place-items-center activeItem font-deco-alt select-none',
          'w-[36px] h-[36px] rounded-full text-xl',
          'touch:md:rounded-none text-xl touch:md:w-fit touch:md:h-[71px] touch:md:text-lg touch:md:px-sm',
          'lg:rounded-none lg:w-fit lg:h-[72px] lg:text-lg lg:px-sm',
        ]"
      >
        <FaIcon
          v-if="icon"
          :icon-class="icon"
          class="text-[20px]"
          :class="{ 'text-white': isActivePage && !isActive }"
        />
        <span
          v-if="isDesktopview"
          class="whitespace-nowrap"
          :class="{ 'text-white': isActivePage && !isActive }"
        >
          <slot :is-active="isActive" />
        </span>
        <div
          v-if="notifications !== null"
          class="hidden touch:md:block lg:block touch:md:w-[7px] lg:w-[7px]"
        />
      </div>
      <div
        v-if="notifications !== null"
        class="relative h-[36px] w-0 touch:mg:w-0 lg:w-0"
      >
        <div
          class="select-none w-[16px] h-[16px] bg-status-warn-base font-deco-alt text-[12px] pr-[1px] rounded-full flex justify-center font-bold items-center absolute top-0 -right-2xs touch:md:right-sm lg:right-sm"
          :class="notifications > 99 ? '!w-[28px] lg:!right-[5px]' : undefined"
        >
          {{ notifications > 99 ? '99+' : notifications }}
        </div>
      </div>
    </button>
  </div>
  <Teleport to="body">
    <div
      v-show="isActive && notifactionState && mobileNotificationName"
      class="fixed top-0 sm:hidden h-[72px] bg-white flex flex-row items-center px-md flex-nowrap w-full left-0 z-[512]"
    >
      <div class="text-lg font-bold font-deco-alt">
        {{ mobileNotificationName ?? '' }}
      </div>
      <div class="ml-auto cursor-pointer" @click="setIsActive(false)">
        <FaIcon
          icon-class="fas fa-xmark"
          class="text-xl text-grey-base"
        ></FaIcon>
      </div>
    </div>
    <div
      v-show="isActive && notifactionState && mobileNotificationName"
      class="fixed top-0 left-0 sm:hidden h-full w-full bg-black !z-[511] opacity-60"
      @click="setIsActive(false)"
    ></div>
    <div
      v-if="isActive && showOverlay"
      :style="{ top: top + 'px' }"
      class="bottom-0 left-0 bg-black overlayRight opacity-20 z-[999] h-[100vh] sm:h-[calc(100vh-72px)] fixed"
      @mousemove="notifactionState ? () => {} : setIsActive(false)"
      @click="setIsActive(false)"
    ></div>
    <div
      v-show="isActive"
      :style="{
        left: isMobileScreen ? undefined : left + 'px',
        top: top + 'px',
        right: isMobileScreen ? 'var(--scrollbar-width, 0px)' : 'none',
      }"
      :class="[stayOpenedClass]"
      class="w-[calc(100%-var(--scrollbar-width,0px))] hotlink-width hotlink-height shadow-lg shadow-gray-base fixed top-[72px] pb-[15px] rounded-b-alt-lg z-[998] bg-white border-t-8 border-activesection-alpha"
      @mousemove="() => (!contentHovered ? (contentHovered = true) : () => {})"
    >
      <slot name="dropdown" :close-cb="() => setIsActive(false)" />
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
import { useHotlink, HotlinkWidth, HotlinkHeight } from './useHotlink';
import { UseStateKeys } from '@/useStateKeys';

const props = defineProps({
  to: {
    type: String,
    required: false,
    default: null,
  },
  ident: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
    default: null,
  },
  notifications: {
    type: Number,
    required: false,
    default: null,
  },
  isActivePage: {
    type: Boolean,
    required: false,
    default: false,
  },
  width: {
    type: Number as PropType<HotlinkWidth>,
    required: false,
    default: HotlinkWidth.default,
  },
  height: {
    type: String as PropType<HotlinkHeight>,
    required: false,
    default: HotlinkHeight.default,
  },
  notificationKey: {
    type: String as PropType<
      | UseStateKeys.HOTLINK_BASKET_NOTIFICATION
      | UseStateKeys.HOTLINK_MYPRODUCTS_NOTIFICATION
      | UseStateKeys.HOTLINK_NO_NOTIFICATION
    >,
    required: false,
    default: UseStateKeys.HOTLINK_NO_NOTIFICATION,
  },
  mobileNotificationName: {
    type: String,
    required: false,
    default: null,
  },
  showOverlay: {
    type: Boolean,
    required: false,
    default: true,
  },
  touchLinkOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
  activeMobileHoverIdent: {
    type: String,
    required: false,
    default: '',
  },
  testid: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits<{
  (event: 'click'): void;
  (event: 'set-active-hover', value: string): void;
}>();

const {
  stayOpenedClass,
  isActive,
  element,
  left,
  top,
  setIsActive,
  calculatePosition,
  size,
  notifactionState,
  isDesktopview,
  isMobileScreen,
  isTouch,
  contentHovered,
} = useHotlink(
  props.ident,
  props.width,
  props.height,
  props.notificationKey,
  props.showOverlay,
);

const isMobileHover = computed(
  () => props.ident == props.activeMobileHoverIdent,
);

onMounted(() => {
  calculatePosition();
  window.addEventListener('scroll', onScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll);
});

function onScroll() {
  calculatePosition();
}

watch(notifactionState, (nv) => {
  setIsActive(nv, true);
  if (isMobileScreen?.value) usePreventBodyScroll(nv);
});

watch(isMobileHover, (nv) => {
  if (!nv) setIsActive(false, false);
});

watch(
  () => props.notifications,
  () => {
    nextTick(() => calculatePosition());
  },
);

function onTouchClick(e: TouchEvent) {
  e.preventDefault();

  if (props.touchLinkOnly) {
    props.to ? useRouter().push(props.to) : emit('click');
  } else {
    if (isActive.value) {
      props.to ? useRouter().push(props.to) : emit('click');
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }
  emit('set-active-hover', props.ident);
}
</script>

<style scoped lang="postcss">
.activeTrigger.active,
.activeTrigger:hover {
  .activeItem {
    @apply bg-primary-lightest;
  }
}
.activeTrigger:active {
  .activeItem {
    @apply bg-primary-base text-white;

    :global(.js-styled-my img) {
      @apply opacity-40;
    }
  }
}

.overlayRight {
  right: var(--scrollbar-width, 0px);
}

.hotlink-width {
  @apply sm:w-[v-bind(size.width)];
}

.hotlink-height {
  @apply sm:h-[v-bind(size.height)];
}
</style>

<template>
  <div
    v-if="isActive"
    class="absolute top-0 left-0 w-full bg-black opacity-20 z-[600] mt-[71px] h-screen"
  />
  <div
    ref="searchbar"
    class="w-full max-w-[690px] px-2xs md:px-md min-w-[245px] hidden md:block py-sm relative"
    :class="{
      'bg-primary-lighter': isActive,
    }"
  >
    <label
      class="box-border w-full leading-[22px] py-xs pl-md pr-[22px] rounded-full flex flex-row gap-xs font-deco-alt text-lg text-text-light"
      :class="[isActive ? 'bg-white' : 'bg-white md:bg-background-base']"
    >
      <input
        v-model="search"
        class="w-full p-0 border-none overflow-elipsis focus:ring-transparent"
        :class="[isActive ? 'bg-white' : 'bg-white md:bg-background-base']"
        :placeholder="!isActive ? t('header.search.placeholder') : ''"
        data-testid="productSearchTextBox"
        @input="
          (event: any) => (search = (event.target as HTMLInputElement).value)
        "
        @click="openHistory"
        @keyup.enter="
          () => {
            if (search) {
              closeHistory();
              addSearchQueryToSearchHistory(search);
            }
          }
        "
      />
      <div
        v-if="search"
        class="relative cursor-pointer text-grey-dark seperator pr-xs"
        @click="clear"
      >
        <FaIcon icon-class="far xmark" />
      </div>
      <div class="cursor-pointer text-grey-dark">
        <FaIcon
          icon-class="far magnifying-glass"
          class="text-xl text-text-light"
          data-testid="productSearchIcon"
          @click.prevent="
            () => {
              if (search) {
                closeHistory();
                addSearchQueryToSearchHistory(search);
              }
            }
          "
        />
      </div>
    </label>
    <div v-if="isActive" class="absolute w-full top-[72px] left-0 z-[610]">
      <div class="w-full h-2 bg-primary-light" />
      <!--div class="h-4xl bg-background-base">campaigns here</div-->
      <div class="bg-white rounded-b-alt-lg pb-md">
        <PopularSearchqueries
          v-if="
            (searchHistory.length === 0 && search.length < 2) ||
            (resultHasNoSuggestions && search.length >= 2)
          "
          @close-history="closeHistory"
          @update-input="(value) => updateSearch(value)"
        />
        <LastSearchqueries
          v-else-if="
            (searchHistory.length > 0 && search.length < 2) ||
            (resultHasNoSuggestions && !search)
          "
          @close-history="closeHistory"
          @update-input="(value) => updateSearch(value)"
        />
        <div v-else-if="search?.length >= 2 && !resultHasNoSuggestions">
          <SearchSuggestions
            v-if="suggestionResult"
            :suggestion-result="suggestionResult"
            :search-input="search"
            @update-input="updateSearch(search)"
            @close-suggestions="closeHistory()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
import LastSearchqueries from './lastSearchqueries.vue';
import PopularSearchqueries from './popularSearchqueries.vue';
import { useSearchbar } from './useSearchbar';
import SearchSuggestions from './searchSuggestions.vue';

const { t } = useTrans();

const search = ref('');
const searchbar = ref();
const isActive = ref(false);
const suggestionResult = ref();

const resultHasNoSuggestions = computed(
  () =>
    suggestionResult.value === undefined ||
    (suggestionResult?.value?.products?.length === 0 &&
      suggestionResult?.value?.suggestions?.length === 0),
);

watch(
  () => useRoute().path,
  () => {
    const route = useRoute();
    if (route.path.startsWith('/shop/search') && route.params.slug?.length) {
      search.value = decodeURIComponent(route.params.slug[0]);
    } else {
      clear();
    }
  },
);

watch(
  () => search.value,
  async (newValue) => {
    if (newValue.length >= 2) {
      debounceSearch(newValue);
    }
    //reset the suggestionResult to show the right flyout
    if (newValue.length <= 1) {
      suggestionResult.value = undefined;
    }
  },
);

const { addSearchQueryToSearchHistory, searchSuggestions, searchHistory } =
  useSearchbar();

const debounceSearch = useDebounceFn(async (searchInput: string) => {
  if (search.value.length < 2) return;
  suggestionResult.value = await searchSuggestions(searchInput);
}, 500);

function clear() {
  search.value = '';
  suggestionResult.value = undefined;
}

function openHistory() {
  isActive.value = true;
}

function closeHistory() {
  isActive.value = false;
}

function updateSearch(input: string) {
  search.value = input;
  addSearchQueryToSearchHistory(search.value);
  closeHistory();
}
</script>
<style scoped lang="postcss">
.seperator::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 0px;
  border: 1px solid;
  border-color: var(--thm-border-light);
  right: 0;
}
</style>

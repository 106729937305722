<template>
  <div ref="container">
    <div class="flex justify-between p-md pb-sm">
      <span class="font-bold font-deco-alt text-alt-2xl">{{
        t('header.search.popularSearchqueries')
      }}</span>
    </div>
    <div>
      <NuxtLink
        v-for="(search, index) in searchQueries"
        :key="index"
        :to="`/shop/search/${encodeURIComponent(search)}`"
      >
        <div
          class="font-text px-md py-xs hover:bg-primary-lightest hover:cursor-pointer"
          @click="$emit('updateInput', search)"
          @key.enter="$emit('updateInput', search)"
        >
          <FaIcon icon-class="far fa-search" classes="mr-xs text-grey-dark" />
          <span>{{ search }}</span>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
import { useSearchbar } from './useSearchbar';
import { SSR_safe_mq_breakpointIsMobile } from '~/injectionSymbols';

const isMobile = inject(SSR_safe_mq_breakpointIsMobile);
const { t } = useTrans();
const container = ref();
const searchQueries = ref();

onClickOutside(container, () => emits('closeHistory'));

const emits = defineEmits<{
  (e: 'closeHistory'): void;
  (e: 'updateInput', value: string): void;
}>();

const { searchSuggestions } = useSearchbar();

onMounted(async () => {
  const popularSearchQeuries = await searchSuggestions('');
  searchQueries.value = popularSearchQeuries?.suggestions.slice(-5);

  if (isMobile?.value) usePreventBodyScroll(true);
});

onUnmounted(() => {
  usePreventBodyScroll(false);
});
</script>

<template>
  <div>
    <StyledMy :text="text" :font-size-px="fontSizePx" :style-type="styleType" />
  </div>
</template>
<script setup lang="ts">
import { EStyleType } from '@/components/layout/header/components/styledMy/useStyledMy';

defineProps({
  text: {
    type: String,
    required: true,
  },
  fontSizePx: {
    type: Number,
    required: false,
    default: 18,
  },
  styleType: {
    type: Number as PropType<EStyleType>,
    required: false,
    default: () => EStyleType.default,
  },
});

const StyledMy = defineAsyncComponent(() => {
  if (useSiteIdent() === SiteIdent.default) {
    return import('./vdv/styledMy.vue');
  } else {
    return import('./minilu/styledMy.vue');
  }
});
</script>

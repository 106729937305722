<template>
  <div
    v-if="hasItemWithCampaign"
    class="flex flex-row bg-primary-lightest rounded-alt-lg"
  >
    <MiniBadge class="ml-sm" />

    <div class="px-sm py-xs">
      {{ t('shop.cart.general.campaignText') }}
      <div
        class="flex flex-row gap-2 cursor-pointer place-items-center text-primary-base mt-xs"
        @click="$router.push('/checkout/basket')"
      >
        <span class="anim__underline-link anim-link">
          {{ t('shop.cart.general.toCart') }}
        </span>
        <Icon icon-class="fas fa-chevron-right" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Icon } from '@/complib';
import MiniBadge from '~/components/page/product/components/campaignBadge/miniCampaignBadge.vue';
import type { LineItem } from '~/server/transformers/shop/lineItemTransformer';

const props = defineProps({
  lineItems: {
    type: Array as PropType<LineItem[]>,
    required: true,
  },
});

const { t } = useTrans();

const hasItemWithCampaign = computed(() => {
  return props.lineItems?.some(
    (item) =>
      item.product?.campaignBadges.length &&
      item.product?.campaignBadges.some((badge) => badge.isBuyable),
  );
});
</script>

<template>
  <div class="mt-[20px]">
    <NuxtLink
      class="item"
      to="/productlists/viewed-products"
      :class="{ active: $route.path === '/productlists/viewed-products' }"
      data-testid="headerLinkViewedProducts"
      @click="$emit('close')"
      >{{ t('header.links.lastSeen') }}</NuxtLink
    >
    <NuxtLink
      class="item"
      to="/productlists/ordered-products"
      :class="{ active: $route.path === '/productlists/ordered-products' }"
      data-testid="headerLinkOrderdProducts"
      @click="$emit('close')"
      >{{ t('header.links.alreadyBought') }}</NuxtLink
    >
    <NuxtLink
      class="item"
      to="/productlists/order-alarm"
      :class="{ active: $route.path === '/productlists/order-alarm' }"
      data-testid="headerLinkOrderAlarm"
      @click="$emit('close')"
      >{{ t('header.links.orderAlarm') }}</NuxtLink
    >
    <NuxtLink
      class="item"
      to="/productlists/auto-delivery"
      :class="{ active: $route.path === '/productlists/auto-delivery' }"
      data-testid="headerLinkAutoDelivery"
      @click="$emit('close')"
      >{{ t('header.links.productAbo') }}</NuxtLink
    >

    <div class="bg-border-light h-[1px] mx-md my-sm"></div>
    <p class="font-bold text-alt-xl font-deco-alt py-xs px-md">
      {{ t('header.links.favoritelist') }}
    </p>
    <NuxtLink
      class="item"
      to="/productlists/favorites"
      :class="{ active: $route.path === '/productlists/favorites' }"
      data-testid="headerLinkFavorites"
      @click="$emit('close')"
      >{{ t('header.links.myFavorites') }}</NuxtLink
    >
    <NuxtLink
      v-for="(item, index) of computedFavNavItems"
      :key="index"
      :to="item.link"
      class="item"
      :class="{ active: $route.path === item.link }"
      @click="$emit('close')"
    >
      {{ t(item.title) }}
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
import { useFavLists } from '~/stores/useFavLists';
import { transformStringForUrl } from '~/helpers/shop';
import { useSessionStore } from '~/stores/useSessionStore';

defineEmits<{
  (e: 'close'): void;
}>();

const { t } = useTrans();
const session = useSessionStore();
const favListStore = useFavLists();

type MyProductsNavType = {
  id: string;
  title: string;
  link: string;
  updatedAt: string;
};

if (session.isLoggedIn) {
  await favListStore.loadLists();
}

const computedFavNavItems = computed(() => {
  const favListHotlinkItems: MyProductsNavType[] = [];
  favListStore.favoriteLists.forEach((list) =>
    favListHotlinkItems.push({
      id: list.id,
      title: list.name,
      link: '/productlists/favorites/' + transformStringForUrl(list.id),
      updatedAt: list.updatedAt || list.createdAt,
    }),
  );

  return favListHotlinkItems
    .sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    )
    .splice(0, 3);
});
</script>
<style scoped lang="postcss">
.item {
  @apply flex flex-row cursor-pointer font-deco-alt flex-nowrap text-alt-lg py-xs px-md hover:bg-primary-lightest hover:text-text-base;

  &.active {
    @apply bg-primary-base text-white;
  }
}
</style>

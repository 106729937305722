import { UseStateKeys } from '@/useStateKeys';
import {
  SSR_safe_mq_breakpointIsMobile,
  SSR_safe_mq_isTouch,
} from '~/injectionSymbols';

const hoverHeightFull = `calc(100% - 72px)`;
const hoverHeightDefault = `auto`;

export function useHotlink(
  ident: string,
  hotlinkWidth: HotlinkWidth,
  hoverHeight: HotlinkHeight,
  notifactionStateKey:
    | UseStateKeys.HOTLINK_BASKET_NOTIFICATION
    | UseStateKeys.HOTLINK_MYPRODUCTS_NOTIFICATION
    | UseStateKeys.HOTLINK_NO_NOTIFICATION = UseStateKeys.HOTLINK_NO_NOTIFICATION,
  showOverlay = true,
) {
  const stayOpenedClass = `js-${ident}`;
  const isActive = ref(false);
  const element = ref(null); // z.B. watch element attrs watch(newval,oldval)
  const left = ref(0);
  const top = ref(0);
  const contentHovered = ref(false);

  const isDesktopview = useIsDesktopView();
  const isMobileScreen = inject(SSR_safe_mq_breakpointIsMobile);
  const isTouch = inject(SSR_safe_mq_isTouch);
  const hoverWidth = hotlinkWidth + 'px';
  const size = {
    width: hoverWidth,
    height:
      hoverHeight === hoverHeightDefault ? hoverHeightDefault : hoverHeightFull,
  };

  const { y } = useWindowScroll();
  watch(
    () => y.value,
    () => {
      if (
        isMobileScreen &&
        isActive &&
        /iPad|iPhone|iPod/.test(navigator.userAgent) // => workaround: stuttering dropdown
      ) {
        setIsActive(false);
      }
    },
  );

  let notificationTimeout: NodeJS.Timeout | string | number | undefined | null =
    null;
  let notificationTimeoutDone = false;
  const notifactionState = useState(notifactionStateKey, () => {
    return false;
  });

  watch(
    () => isActive.value,
    (nv) => {
      if (showOverlay) {
        usePreventBodyScroll(nv);
      }
    },
  );

  const { init: closeOnLeaveInit } = useCloseOnLeave(stayOpenedClass, () => {
    if (
      notifactionState.value &&
      contentHovered.value &&
      !notificationTimeoutDone
    ) {
      debouncedCloseOnLeaveInit();
      return;
    }
    if (
      !notifactionState.value ||
      (notifactionState.value && contentHovered.value)
    ) {
      setIsActive(false);
    }
  });
  const debouncedCloseOnLeaveInit = useDebounceFn(closeOnLeaveInit, 200);

  function setIsActive(value: boolean, isNotification = false) {
    if (notificationTimeout && value && isNotification) {
      return;
    }

    if (value) {
      if (
        isNotification &&
        notifactionStateKey !== UseStateKeys.HOTLINK_NO_NOTIFICATION
      ) {
        notifactionState.value = true;
        notificationTimeout = setTimeout(() => {
          notificationTimeoutDone = true;
          if (!contentHovered.value) {
            notifactionState.value = false;
            notificationTimeout = null;
          }
        }, 5000);
      } else {
        closeOnLeaveInit();
      }
      calculatePosition();
      isActive.value = value;
    } else {
      if (notificationTimeout) {
        clearTimeout(notificationTimeout);
        notificationTimeout = null;
      }
      notificationTimeoutDone = false;
      contentHovered.value = false;
      notifactionState.value = false;
      isActive.value = value;
    }
  }

  function calculatePosition() {
    if (element.value === null) {
      return;
    }
    const rect = element.value?.getBoundingClientRect();
    const parentRect =
      element.value.parentElement.parentElement?.getBoundingClientRect();
    top.value = parentRect.top + parentRect.height;
    const overflow = Math.max(
      rect.left + hotlinkWidth + 20 - window.innerWidth,
      0,
    );
    if (!overflow) {
      left.value = rect.left;
    } else {
      left.value = Math.max(rect.left - hotlinkWidth + rect.width, 0);
    }
  }

  watch(contentHovered, (nv) => {
    if (nv === true) {
      closeOnLeaveInit();
    }
  });

  return {
    stayOpenedClass,
    isActive,
    element,
    left,
    top,
    setIsActive,
    calculatePosition,
    notifactionState,
    size,
    isDesktopview,
    isMobileScreen,
    isTouch,
    contentHovered,
  };
}

export enum HotlinkWidth {
  default = 345,
  wide = 450,
}

export enum HotlinkHeight {
  default = 'auto',
  full = 'calc(100% - 72px)',
}

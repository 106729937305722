<template>
  <NuxtLink :disabled="disabled" to="/checkout/basket">
    <button
      title="Shopping-Cart"
      class="cartBtn custom-button"
      @click="emit('click')"
    >
      {{ t('shop.cart.general.basketLink') }}
    </button>
  </NuxtLink>
</template>

<script setup lang="ts">
defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { t } = useTrans();
const emit = defineEmits(['click']);
</script>
<style lang="postcss" scoped>
.cartBtn {
  @apply flex items-center place-content-center rounded-btn font-bold border-[2px] !py-[4px] border-btn-cta-base bg-white cursor-pointer w-full text-center text-btn-cta-base;
}
</style>

<template>
  <div class="flex flex-row visible md:hidden">
    <div
      v-if="isActive"
      class="flex items-center justify-start w-0 overflow-hidden transition-all ease-linear"
      :class="{ '!w-md': search }"
      @click="() => (search = '')"
    >
      <FaIcon icon-class="fal arrow-left" />
    </div>
    <div class="min-w-[245px] w-full max-w-full">
      <div
        ref="searchbarMobile"
        class="flex items-center w-full py-sm gap-sm md:hidden"
        :class="[
          isActive
            ? 'bg-primary-lighter absolute -top-[80px] right-0 left-0 z-[610] px-sm'
            : 'relative',
        ]"
      >
        <div v-if="isActive">
          <FaIcon icon-class="fal arrow-left" @click="closeHistory" />
        </div>
        <label
          class="box-border w-full leading-[22px] py-xs px-[22px] rounded-full items-center flex flex-row gap-xs font-deco-alt text-alt-lg text-text-light bg-white"
        >
          <input
            :value="search"
            class="w-full p-0 bg-white border-none overflow-elipsis focus:ring-transparent"
            :placeholder="!isActive ? t('header.search.placeholderMobile') : ''"
            @input="
              (event: any) =>
                (search = (event.target as HTMLInputElement).value)
            "
            @click="openHistory"
            @keyup.enter="
              () => {
                if (search) {
                  closeHistory();
                  addSearchQueryToSearchHistory(search);
                }
              }
            "
          />
          <div v-if="search" class="relative text-grey-dark" @click="clear">
            <FaIcon icon-class="far xmark" />
          </div>
          <div
            class="text-grey-dark"
            @click.prevent="
              () => {
                if (search) {
                  closeHistory();
                  addSearchQueryToSearchHistory(search);
                }
              }
            "
          >
            <FaIcon
              icon-class="far magnifying-glass"
              class="text-xl text-text-light"
            />
          </div>
        </label>
        <div
          v-if="isActive"
          class="absolute h-full w-full top-[72px] left-0 z-[610]"
        >
          <!--div class="bg-background-base">campaigns here</div-->
          <div class="h-screen bg-white rounded-b-alt-lg pb-md">
            <PopularSearchqueries
              v-if="
                (searchHistory.length === 0 && search.length < 2) ||
                (resultHasNoSuggestions && search.length >= 2)
              "
              @close-history="closeHistory"
              @update-input="(value) => updateSearch(value)"
            />
            <LastSearchqueries
              v-else-if="
                (searchHistory.length > 0 && search.length < 2) ||
                (resultHasNoSuggestions && !search)
              "
              @close-history="closeHistory"
              @update-input="(value) => updateSearch(value)"
            />
            <div v-else-if="search?.length >= 2 && !resultHasNoSuggestions">
              <SearchSuggestions
                v-if="suggestionResult"
                :suggestion-result="suggestionResult"
                :search-input="search"
                @update-input="updateSearch(search)"
                @close-suggestions="closeHistory()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
import LastSearchqueries from './lastSearchqueries.vue';
import { useSearchbar } from './useSearchbar';
import SearchSuggestions from './searchSuggestions.vue';
import PopularSearchqueries from './popularSearchqueries.vue';

const { t } = useTrans();
const search = ref('');
const searchbarMobile = ref();
const isActive = ref(false);
const suggestionResult = ref();

const resultHasNoSuggestions = computed(
  () =>
    suggestionResult.value === undefined ||
    (suggestionResult?.value?.products?.length === 0 &&
      suggestionResult?.value?.suggestions?.length === 0),
);

const { addSearchQueryToSearchHistory, searchSuggestions, searchHistory } =
  useSearchbar();

watch(
  () => useRoute().path,
  () => {
    const route = useRoute();
    if (route.path.startsWith('/shop/search') && route.params.slug?.length) {
      search.value = decodeURIComponent(route.params.slug[0]);
    } else {
      clear();
    }
  },
);

watch(
  () => search.value,
  async (newValue) => {
    if (newValue.length >= 2) {
      debounceSearch(newValue);
    }
    //reset the suggestionResult to show the right flyout
    if (newValue.length <= 1) {
      suggestionResult.value = undefined;
    }
  },
);

const debounceSearch = useDebounceFn(async (searchInput: string) => {
  if (search.value.length < 2) return;
  suggestionResult.value = await searchSuggestions(searchInput);
}, 500);

function clear() {
  search.value = '';
  suggestionResult.value = undefined;
}

function openHistory() {
  isActive.value = true;
}

function closeHistory() {
  isActive.value = false;
}

function updateSearch(input: string) {
  search.value = input;
  addSearchQueryToSearchHistory(search.value);
  closeHistory();
}
</script>
